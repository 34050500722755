class Magnific {
    constructor() {
        console.log('>>> Magnific constructor');
        this.initGallery();
    }

    initGallery() {
        $('.popup-gallery').magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Carregando imagem #%curr%...',
            mainClass: 'mfp-fade',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1],
                tPrev: 'Anterior',
                tNext: 'Próxima',
                tCounter: '%curr% de %total%'
            },
            image: {
                tError: '<a href="%url%">A imagem #%curr%</a> não foi encontrada.',
                titleSrc: function(item) {
                    return item.el.attr('title');
                }
            }
        });
    }
}

export default Magnific;
