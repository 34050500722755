class Translate {
    constructor() {
        console.log('>>> Translate constructor');
        this.initTranslate();
    }

    initTranslate() {
        const that = this,
              $en   = $('.translate__en'),
              $pt   = $('.translate__br'),
              $body = $('body');

        if( sessionStorage.getItem('lang') === 'en') {
            that.ingles();
            that.activeButton();
        }

        // Envoca a função ingles e seta lang = en
        $en.on('click tap touch', function(e) {
            sessionStorage.setItem('lang', 'en');
            $body.fadeOut(400);
            
            setTimeout(() => {
                that.ingles();                
                that.activeButton();
            }, 600);
        });

        // Altera o valor de lang para lang = pt
        $pt.on('click tap touch', function(e) {
            sessionStorage.setItem('lang', 'br');
        });
    }

    ingles() {
        // Objeto que contem todos os textos
        const biblioteca  = {};
        
        // Qual pagina esta sendo acessada
        const currentPage = $('body').data('page');
        switch (currentPage) {
            case 'home'     : pgHome();
                break;
            case 'contato'  : pgContato();
                break;
            case 'sobre'    : pgSobre();
                break;
            case 'estacoes' : pgEstacoes();
                break;
            case 'projetos' : pgProjetos();
                break;
            case 'bara'     : pgBara();  
                break;
            case 'vili'     : pgVili();
                break;
            case 'h23'      : pgH23();
                break;
            case 'neo'      : pgNeo();
                break;
            case 'neue'     : pgNeue();
                break;
            case 'atlantica': pgAtlantica();
                break;
            case 'voll29'   : pgVll29();
                break;
            case 'hortus'   : pgHortus();
                break;
            case 'complexoHc': pgComplexoHc();
                break;
            case 'casulo': pgCasulo();
                break;
        }

        function pgHome() {
            // Textos para tradução em ingles
            biblioteca.homeLinkSobre    = 'about';
            biblioteca.homeLinkContato  = 'contact';
            biblioteca.homeLinkProjetos = 'projects';
            biblioteca.homeLinkEstacoes = 'stations';
            biblioteca.homeSubtitle1    = 'infi-';
            biblioteca.homeSubtitle2    = 'nite';
            biblioteca.homeSubtitle3    = 'possi-';
            biblioteca.homeSubtitle4    = 'bilities';
            
            // Execução da função text no DOM
            const $homeLinkSobre    = $('.is-homeLinkSobre').text(biblioteca.homeLinkSobre);
            const $homeLinkContato  = $('.is-homeLinkContato').text(biblioteca.homeLinkContato);
            const $homeLinkProjetos = $('.is-homeLinkProjetos').text(biblioteca.homeLinkProjetos);
            const $homeLinkEstacoes = $('.is-homeLinkEstacoes').text(biblioteca.homeLinkEstacoes); 
            const $homeSubtitle1 = $('.is-homeSubtitle1').text(biblioteca.homeSubtitle1); 
            const $homeSubtitle2 = $('.is-homeSubtitle2').text(biblioteca.homeSubtitle2); 
            const $homeSubtitle3 = $('.is-homeSubtitle3').text(biblioteca.homeSubtitle3); 
            const $homeSubtitle4 = $('.is-homeSubtitle4').text(biblioteca.homeSubtitle4); 
        }
        
        function pgContato() {
            menu();

            // Textos para tradução em ingles
            biblioteca.contatoTitle1    =   'Location';
            biblioteca.contatoTitle2    =   'Contacts';
            
            // Execução da função text no DOM
            const $contatoTitle1    = $('.is-contatoTitle1').text(biblioteca.contatoTitle1); 
            const $contatoTitle2    = $('.is-contatoTitle2').text(biblioteca.contatoTitle2); 
        }

        function pgSobre() {
            menu();

            // Textos para tradução em ingles
            biblioteca.sobreTitle1Parte1    =   'who';
            biblioteca.sobreTitle1Parte2    =   'we are';
            biblioteca.sobreText1           =   'A Station that contemplates the most complete skills and committed professionals engaged to in give tour best. Believing in people, in society and in ouro team that vibrates, move us to realizes and shear the plurality of knowledge aiming to reach all the expectations.';

            biblioteca.sobreTitle2Parte1    =   'What we';
            biblioteca.sobreTitle2Parte2    =   'do';
            biblioteca.sobreText2           =   'Our goal is to connect and inspire people by transforming their hearts and minds. We offer services ranging from market research, legal modeling, business planning and process auditing to marketing strategies, sales, design and concepts. Infinite possibilities to ensure that the project reaches the desired destination.';

            biblioteca.sobreTitle3Parte1    =   'Our';
            biblioteca.sobreTitle3Parte2    =   'station';
            biblioteca.sobreText3           =   'Three floors of creative energy that houses companies focused on people. We have a mix of services such as structuring, Sales and Rental, Management, Advertising, Architecture, Gastronomy, Coworking and Design. An Ecosystem with several fronts in search of the same result, success in what we do.';
            
            // Execução da função text no DOM
            const $sobreTitle1Parte1    = $('.is-sobreTitle1Parte1').text(biblioteca.sobreTitle1Parte1); 
            const $sobreTitle1Parte2    = $('.is-sobreTitle1Parte2').text(biblioteca.sobreTitle1Parte2); 
            const $sobreText1           = $('.is-sobreText1').text(biblioteca.sobreText1); 

            const $sobreTitle2Parte1    = $('.is-sobreTitle2Parte1').text(biblioteca.sobreTitle2Parte1); 
            const $sobreTitle2Parte2    = $('.is-sobreTitle2Parte2').text(biblioteca.sobreTitle2Parte2); 
            const $sobreText2           = $('.is-sobreText2').text(biblioteca.sobreText2); 

            const $sobreTitle3Parte1    = $('.is-sobreTitle3Parte1').text(biblioteca.sobreTitle3Parte1); 
            const $sobreTitle3Parte2    = $('.is-sobreTitle3Parte2').text(biblioteca.sobreTitle3Parte2); 
            const $sobreText3           = $('.is-sobreText3').text(biblioteca.sobreText3); 
        }

        function pgEstacoes() {
            menu();

            // Textos para tradução em ingles
            biblioteca.estacoesTitle1       = 'structuring';
            biblioteca.estacoesText1Parte1  = 'Rather than simply performing financial and chronological calculations, we believe in the importance of a broad business vision. To see the different scenarios and objectives in order to generate value, is what makes us move the residential, hotel, business and commercial world.';
            biblioteca.estacoesText1Parte2  = 'People engaged to design your business in the desired mode and stage. New areas for construction, formation of investor groups, economic-financial structuring of the project, management and commercialization of the units.';
            biblioteca.estacoesText1Parte3  = 'Real estate expertise and participation in the feasibility of the developments by the structuring of incorporations, at a closed price, cost price or condominium regime, in the probative processes - legalization of the incorporation, land, work project approvals.';

            biblioteca.estacoesTitle2       = 'management';
            biblioteca.estacoesText2Parte1  = 'We count on a team engaged in studies and market research, to be successful and to walk allied to the competence.';
            biblioteca.estacoesText2Parte2  = 'Coordinating superior strategies to develop the best action plans, able to obtain the necessary visibility, and the most potent results.';

            biblioteca.estacoesTitle3       = 'architecture';
            biblioteca.estacoesText3Parte1  = 'We interpret ideas, concepts and lifestyle to convert them into architectural projects and scenarios for reality, using professional techniques and creativity.';
            biblioteca.estacoesText3Parte2  = 'We created spaces where they did not exist before, illuminating what before, was erased. Filling, with places, the life where it was empty.';
            biblioteca.estacoesText3Parte3  = 'By seamlessly mixing the natural with the urban and the conventional with the innovator, we put our experience in design and architecture into projects so that they can prevail over time and become something physically admirable, leaving only paper.';

            biblioteca.estacoesTitle4       = 'design';
            biblioteca.estacoesText4Parte1  = 'In all the process of real estate development, we always prioritize a good aesthetic. For this, we have brought together an interdisciplinary team of designers, graphic artists and art directors.';
            biblioteca.estacoesText4Parte2  = 'Our team collaborates with clients from start to finish of the process, developing intelligent concepts and sharing solutions. We use art, photography, signage design, installation and other adjacent as a means of producing strong visual identities. We believe it is essential to revisit each project so that we can achieve an authentic solution.';
            biblioteca.estacoesText4Parte3  = "Our goal is to develop products, environments and communications that transform and drive business to improve people's lives. We put our imagination at the center of everything we do, creating daring and unforgettable worlds. Weaving the pragmatist with poetry, breaking the usual and chasing the extraordinary.";

            biblioteca.estacoesTitle5           = 'allies';
            biblioteca.estacoesSubtitle1        = 'Gastronomy';
            biblioteca.estacoesSubText1Parte1   = 'In order to awaken the five senses and provide an incredible experience to the client. Our ally is a specialist in connecting people through gastronomy.';

            biblioteca.estacoesSubtitle2        = 'Advocacy';
            biblioteca.estacoesSubText2Parte1   = 'United with the aim of combining experience, tradition and excellence in efficient service delivery, the law firm joins the station providing legal assistance to projects.';

            biblioteca.estacoesSubtitle3        = 'Events, Coworking and Virtual Offices';
            biblioteca.estacoesSubText3Parte1   = 'A portal capable of connecting minds and hearts, providing the most unique experiences, in order to capture the most precious thing. You!';

            biblioteca.estacoesSubtitle4        = 'Architecture';
            biblioteca.estacoesSubText4Parte1   = "Timeless projects that seek to modify people's lives through architecture. LMaia is the ally that brings shapes and expressions to our projects.";

            biblioteca.estacoesSubtitle5        = 'Sales';
            biblioteca.estacoesSubText5Parte1   = "Valuing transparency, personalized service and differentiated to people who move the real estate that has nothing. What moves us? The pleasure of providing experiences.";

            biblioteca.estacoesSubtitle6        = 'Location';
            biblioteca.estacoesSubText6Parte1   = "Focused on the rental, administration of residential and commercial real estate, always in search of the best option, based on good service.";

            biblioteca.estacoesSubtitle7        = 'Construction';
            biblioteca.estacoesSubText7Parte1   = "Massai: With an entrepreneurial spirit, Massai has modified the Northeastern landscape with daring projects, expanding its borders and always keeping an eye on new challenges.";
            biblioteca.estacoesSubText7Parte2   = "Cosseno: Cosseno Construtora owns a history of credibility for excellence in the quality of its works, and also for the absolute transparency in the management of the ventures of its partners.";
            biblioteca.estacoesSubText7Parte3   = "Through architecture and buildings that have the design in their DNA. Haut has the principle of transforming people's lives and positively impacting them. In what way? Exclusive developments, unique concepts.";
            
            // Execução da função text no DOM            
            const $estacoesTitle1       = $('.is-estacoesTitle1').text(biblioteca.estacoesTitle1); 
            const $estacoesText1Parte1  = $('.is-estacoesText1Parte1').text(biblioteca.estacoesText1Parte1); 
            const $estacoesText1Parte2  = $('.is-estacoesText1Parte2').text(biblioteca.estacoesText1Parte2);
            const $estacoesText1Parte3  = $('.is-estacoesText1Parte3').text(biblioteca.estacoesText1Parte3);

            const $estacoesTitle2       = $('.is-estacoesTitle2').text(biblioteca.estacoesTitle2); 
            const $estacoesText2Parte1  = $('.is-estacoesText2Parte1').text(biblioteca.estacoesText2Parte1); 
            const $estacoesText2Parte2  = $('.is-estacoesText2Parte2').text(biblioteca.estacoesText2Parte2);

            const $estacoesTitle3       = $('.is-estacoesTitle3').text(biblioteca.estacoesTitle3); 
            const $estacoesText3Parte1  = $('.is-estacoesText3Parte1').text(biblioteca.estacoesText3Parte1); 
            const $estacoesText3Parte2  = $('.is-estacoesText3Parte2').text(biblioteca.estacoesText3Parte2);
            const $estacoesText3Parte3  = $('.is-estacoesText3Parte3').text(biblioteca.estacoesText3Parte3);

            const $estacoesTitle4       = $('.is-estacoesTitle4').text(biblioteca.estacoesTitle4); 
            const $estacoesText4Parte1  = $('.is-estacoesText4Parte1').text(biblioteca.estacoesText4Parte1); 
            const $estacoesText4Parte2  = $('.is-estacoesText4Parte2').text(biblioteca.estacoesText4Parte2);
            const $estacoesText4Parte3  = $('.is-estacoesText4Parte3').text(biblioteca.estacoesText4Parte3);

            const $estacoesTitle5           = $('.is-estacoesTitle5').text(biblioteca.estacoesTitle5); 
            const $estacoesSubtitle1        = $('.is-estacoesSubtitle1').text(biblioteca.estacoesSubtitle1); 
            const $estacoesSubText1Parte1   = $('.is-estacoesSubText1Parte1').text(biblioteca.estacoesSubText1Parte1);
            
            const $estacoesSubtitle2        = $('.is-estacoesSubtitle2').text(biblioteca.estacoesSubtitle2); 
            const $estacoesSubText2Parte1   = $('.is-estacoesSubText2Parte1').text(biblioteca.estacoesSubText2Parte1);

            const $estacoesSubtitle3        = $('.is-estacoesSubtitle3').text(biblioteca.estacoesSubtitle3); 
            const $estacoesSubText3Parte1   = $('.is-estacoesSubText3Parte1').text(biblioteca.estacoesSubText3Parte1);

            const $estacoesSubtitle4        = $('.is-estacoesSubtitle4').text(biblioteca.estacoesSubtitle4); 
            const $estacoesSubText4Parte1   = $('.is-estacoesSubText4Parte1').text(biblioteca.estacoesSubText4Parte1);

            const $estacoesSubtitle5        = $('.is-estacoesSubtitle5').text(biblioteca.estacoesSubtitle5); 
            const $estacoesSubText5Parte1   = $('.is-estacoesSubText5Parte1').text(biblioteca.estacoesSubText5Parte1);

            const $estacoesSubtitle6        = $('.is-estacoesSubtitle6').text(biblioteca.estacoesSubtitle6); 
            const $estacoesSubText6Parte1   = $('.is-estacoesSubText6Parte1').text(biblioteca.estacoesSubText6Parte1);
            
            const $estacoesSubtitle7        = $('.is-estacoesSubtitle7').text(biblioteca.estacoesSubtitle7); 
            const $estacoesSubText7Parte1   = $('.is-estacoesSubText7Parte1').text(biblioteca.estacoesSubText7Parte1);
            const $estacoesSubText7Parte2   = $('.is-estacoesSubText7Parte2').text(biblioteca.estacoesSubText7Parte2);
            const $estacoesSubText7Parte3   = $('.is-estacoesSubText7Parte3').text(biblioteca.estacoesSubText7Parte3);
            
            // Menu criado pelo plugin carousel
            setTimeout(function(){
                const $menu = $(".owl-dot span");
                $menu.eq(0).text(biblioteca.estacoesTitle1);
                $menu.eq(1).text(biblioteca.estacoesTitle2);
                $menu.eq(2).text(biblioteca.estacoesTitle3);
                $menu.eq(3).text(biblioteca.estacoesTitle4);
                $menu.eq(4).text(biblioteca.estacoesTitle5);
            }, 2200); 
        }

        function pgProjetos() {
            menu();

            // Textos para tradução em ingles
            biblioteca.projetosSubtitle1   =   'Business';
            biblioteca.projetosSubtitle2   =   'Residential';
            biblioteca.projetosSubtitle3   =   'Residential';
            biblioteca.projetosSubtitle4   =   'Residential';
            
            // Execução da função text no DOM
            const $projetosSubtitle1    = $('.is-projetosSubtitle1').text(biblioteca.projetosSubtitle1); 
            const $projetosSubtitle2    = $('.is-projetosSubtitle2').text(biblioteca.projetosSubtitle2); 
            const $projetosSubtitle3    = $('.is-projetosSubtitle3').text(biblioteca.projetosSubtitle3); 
            const $projetosSubtitle4    = $('.is-projetosSubtitle4').text(biblioteca.projetosSubtitle4);  
        }

        function pgBara() {
            menu();
            infoInterna();

            // Textos para tradução em ingles
            biblioteca.baraDescription = 'Creating bridges between guests, believing that the medium of culture and interactions that will be managed in this space, it will be possible to recognize, share human values, create new ways of understanding the world, explore possibilities and solutions for the future.';

            // Execução da função text no DOM
            const $baraDescription = $('.is-baraDescription').text(biblioteca.baraDescription);
        }

        function pgVili() {
            menu();
            infoInterna();

            // Textos para tradução em ingles
            biblioteca.viliDescription = 'DESIGN HAS 6 LETTERS AND 63 SQUARE METERS Developed with modernity and practicality, VILI is a singular enterprise, located in Bessa, at the height of lovers of architecture.';
            biblioteca.viliSubtitle    = 'Residential';

            // Execução da função text no DOM
            const $viliDescription = $('.is-viliDescription').text(biblioteca.viliDescription);
            const $viliSubtitle    = $('.is-viliSubtitle').text(biblioteca.viliSubtitle);
        }

        function pgH23() {
            menu();
            infoInterna();

            // Textos para tradução em ingles
            biblioteca.h23Description = 'Art and business coexist in the H23, a non-standard business.';
            biblioteca.h23Subtitle    = 'Business';

            // Execução da função text no DOM
            const $h23Description = $('.is-h23Description').text(biblioteca.h23Description);
            const $h23Subtitle    = $('.is-h23Subtitle').text(biblioteca.h23Subtitle);
        }

        function pgNeo() {
            menu();
            infoInterna();

            // Textos para tradução em ingles
            biblioteca.neoDescription = 'Inspired by major international luxury brands, the NEO Residential imposes itself in the surroundings with its rhythmic lines and stands out as a light, luxurious and totally independent counterpoint in the Altiplano Skyline.';
            biblioteca.neoSubtitle    = 'Residential';

            // Execução da função text no DOM
            const $neoDescription = $('.is-neoDescription').text(biblioteca.neoDescription);
            const $neoSubtitle    = $('.is-neoSubtitle').text(biblioteca.neoSubtitle);
        }

        function pgNeue() {
            menu();
            infoInterna();

            // Textos para tradução em ingles
            biblioteca.neueDescription = 'With the prospect of transforming the hotel market and leaving a legacy in the cities, NEUE was developed to provide new experiences in a tourist destination.';

            // Execução da função text no DOM
            const $neueDescription = $('.is-neueDescription').text(biblioteca.neueDescription);
        }

        function pgAtlantica() {
            menu();
            infoInterna();

            // Textos para tradução em ingles
            biblioteca.atlanticaDescription = 'With the prospect of transforming the hotel market and leaving a legacy in the cities, NEUE was developed to provide new experiences in a tourist destination.';
            biblioteca.atlanticaSubtitle    = 'Residential';

            // Execução da função text no DOM
            const $atlanticaDescription = $('.is-atlanticaDescription').text(biblioteca.atlanticaDescription);
            const $atlanticaSubtitle    = $('.is-atlanticaSubtitle').text(biblioteca.atlanticaSubtitle);            
        }

        function pgVoll29() {
            menu();
            infoInterna();

            // Textos para tradução em ingles
            biblioteca.voll29Description = 'A completeness of a model that delivers the city to a totality of a practical life, united to a lifestyle and cosmopolitan. The whole urban extension, compacted and one place, Voll 29.';
            biblioteca.voll29Subtitle    = '';

            // Execução da função text no DOM
            const $voll29Description = $('.is-voll29Description').text(biblioteca.voll29Description);
            const $voll29Subtitle    = $('.is-voll29Subtitle').text(biblioteca.voll29Subtitle);            
        }

        function pgHortus() {
            menu();
            infoInterna();

            // Textos para tradução em ingles
            biblioteca.hortusDescription = 'Embrace this community, feel the green, live the Hortus. In the heart of Manaíra, a green lane is born that leads to encounters and spreads the feeling of living in a street where everyone knows each other. An architectural project where concrete and green coexist with hands and green in profusion, make possible a practical and shared life.';
            biblioteca.hortusSubtitle    = '';

            // Execução da função text no DOM
            const $hortusDescription = $('.is-hortusDescription').text(biblioteca.hortusDescription);
            const $hortusSubtitle    = $('.is-hortusSubtitle').text(biblioteca.hortusSubtitle);            
        }

        function pgComplexoHc() {
            menu();
            infoInterna();

            // Textos para tradução em ingles
            biblioteca.complexoHcDescription = 'Consolidated to the concept 10/5/15 the Complex HC demonstrates multiforme, delivering to the neighborhood of Manaíra a Business, Mall, Hotel and Residential. An equipment with the power to transform its environment and the lives of its users.';
            biblioteca.complexoHcSubtitle    = '';

            // Execução da função text no DOM
            const $complexoHcDescription = $('.is-complexoHcDescription').text(biblioteca.complexoHcDescription);
            const $complexoHcSubtitle    = $('.is-complexoHcSubtitle').text(biblioteca.complexoHcSubtitle);
        }

        function pgCasulo() {
            menu();
            infoInterna();

            // Textos para tradução em ingles
            biblioteca.casuloDescription = 'Inspired by Biomimetics, we seek the way that nature adapts, evolves and transforms, to the development of this project. Thus, as the young man who is constantly evolving, this hotel that presents itself as a living ecosystem, accompanies this metamorphosis.';
            biblioteca.casuloSubtitle    = '';

            // Execução da função text no DOM
            const $casuloDescription = $('.is-casuloDescription').text(biblioteca.casuloDescription);
            const $casuloSubtitle    = $('.is-casuloSubtitle').text(biblioteca.casuloSubtitle);
        }

        // Tradução do Menu
        function menu() {
            // Textos para tradução em ingles
            biblioteca.menuLinkSobre    =   'about';
            biblioteca.menuLinkEstacoes =   'stations';
            biblioteca.menuLinkContato  =   'contact';
            biblioteca.menuLinkProjetos =   'projects';
            
            // Execução da função text no DOM
            const $menuLinkSobre    =   $('.is-menuLinkSobre').text(biblioteca.menuLinkSobre); 
            const $menuLinkEstacoes =   $('.is-menuLinkEstacoes').text(biblioteca.menuLinkEstacoes);
            const $menuLinkContato  =   $('.is-menuLinkContato').text(biblioteca.menuLinkContato); 
            const $menuLinkProjetos =   $('.is-menuLinkProjetos').text(biblioteca.menuLinkProjetos); 
        }

        function infoInterna() {
            // Textos para tradução em ingles
            //biblioteca.infoTitle1      = 'developers_';
            biblioteca.infoTitle2      = 'architecture:';
            biblioteca.infoTitle3      = 'construction:';
            biblioteca.infoTitle4      = 'reservations:';
            biblioteca.infoTitle5      = 'development:';
            biblioteca.infoTitle6      = 'interior:';
            biblioteca.infoTitle7      = 'paisagismo:';

            // Execução da função text no DOM
            const $infoTitle1      = $('.is-infoTitle1').text(biblioteca.infoTitle1);
            const $infoTitle2      = $('.is-infoTitle2').text(biblioteca.infoTitle2);
            const $infoTitle3      = $('.is-infoTitle3').text(biblioteca.infoTitle3);
            const $infoTitle4      = $('.is-infoTitle4').text(biblioteca.infoTitle4);
            const $infoTitle5      = $('.is-infoTitle5').text(biblioteca.infoTitle5);
            const $infoTitle6      = $('.is-infoTitle6').text(biblioteca.infoTitle6);
            const $infoTitle7      = $('.is-infoTitle7').text(biblioteca.infoTitle7);
        }

        // Inserção do texto no elemeno 
        function text( texto ) {
            return texto.text(texto);
        }
    }

    // Ativa o botão de linguagem
    activeButton() {
        const $en = $('.translate__en');

        $en.parent().find('.is-active').removeClass('is-active');
        $en.addClass('is-active');
    }


}

export default Translate;
