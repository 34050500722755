class Modal {
    constructor() {
        console.log('>>> Modal constructor');
        this.initModal();
    }

    initModal() {
        const that = this;
        const $cardLink = $('.card-station__link');
        const $modal = $('.modal');
        const $closeModal = $('.modal__close');

        $cardLink.on('click tap touch', function(e) {
            e.preventDefault();

            $('.modal').fadeOut();
            $(this).parent().parent().find('.modal').fadeIn();
        });

        $closeModal.on('click tap touch', function(e) {
            e.preventDefault();

            $('.modal').fadeOut();
        });
    }
}

export default Modal;
