class OwlCarousel {
    constructor() {
        console.log('>>> OwlCarousel constructor');
        this.initOwlCarousel();
    }

    initOwlCarousel() {
        setTimeout(function(){
            if( $(window).outerWidth() >= 991) {
                $('.owl-theme').owlCarousel({
                    margin:10,
                    //loop:true,
                    autoWidth:false,
                    items:1,
                    nav:true
                });
            }
        }, 1000);

        window.onresize = function() {
            if ( $(window).outerWidth() <= 991) {
                $('.owl-carousel').owlCarousel('destroy');
            } 
            else {
                $('.owl-theme').owlCarousel({
                    margin:10,
                    autoWidth:false,
                    items:1,
                    nav:true
                }, dotTitle());
            }
        }

        // Função de ordenação de array
        function ordenation(a, b){
            return (a - b);
        }

        function dotTitle() {
            const $slideTitle      = $('.item');
            let strTitle           = [];
            let title              = [];
            let strTitleOrdenation = [];
            let strOrdenation      = [];

            // Captura titulo do data atribute
            $($slideTitle).each(function(index, element) {
                const $slide = $(element);
                strTitle.push($slide.data('slide-title'));
            });

            // Ordenando array
            strTitle.reverse();
            strTitle.sort(ordenation);

            // Remove array duplicados
            $(strTitle).each(function(i, element) {
                if($.inArray(element, title) === -1) {
                    title.push(element);
                }
                $('.loading').fadeOut();
            });

            // Inserção
            setTimeout(function(){
                $($('.owl-dot span')).each(function(y, element) {
                    $(this).text(title[y]);                    
                });

                $('.owl-theme').css('visibility', 'visible');
            }, 1000);

            // Dot Move
            function dotMove() {
                setTimeout(function(){
                    const owl             = $('.owl-theme');
                    const $circleInsert   = $('<span class="circleMove"></span>').appendTo(owl);
                    const $dotActiveFirst = $('.owl-dot.active').offset().left;
                    let $circle           = $('.circleMove');

                    $circle.css('left', ($dotActiveFirst + 800) + 'px');

                    owl.on('changed.owl.carousel',
                        function() {
                            let $dotActive = $('.owl-dot.active').offset().left;
                            $circle.css('left', $dotActive + 'px');
                        }
                    );
                }, 5650);
            }
        }

        setTimeout(function(){
            dotTitle();
        }, 1100);
    }
}

export default OwlCarousel;
