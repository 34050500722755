import Common from './modules/common';
import Translate from './modules/translate';
import Menu from './modules/menu';
import Carousel from './modules/carousel';
import OwlCarousel from './modules/owlcarousel';
import Modal from './modules/modal';
import Magnific from './modules/magnific';

const availableModules = { Common, Translate, Menu, Carousel, OwlCarousel, Modal, Magnific };

window.modules = {};

$(() => {
    const htmlModules = $('[data-module]');

    // Loading htmlModules if they are in availableModules
    htmlModules.each((key, value) => {
        const mod = $(value).data('module');

        if (Object.prototype.hasOwnProperty.call(availableModules, mod)) {
            window.modules[mod] = new availableModules[mod]();
        } else {
            console.log(`The module "${mod}" does not exists.`);
        }
    });
});
