class Menu {
    constructor() {
        console.log('>>> Menu constructor');
        this.initMenu();
    }

    initMenu() {
        const that = this,
        $navToggle = $('.nav-toggle');

        $navToggle.on('click tap touch', function(e) {
            that.toggleToggle();
        });
    }

    toggleToggle() {
        const $navBar    = $('.navbar');
        const $navToggle = $('.nav-toggle');
        const $body      = $('body');

        $navToggle.toggleClass('-active');
        $navBar.toggleClass('-open');
        $body.toggleClass('navActive');
    }
}

export default Menu;
