class Carousel {
    constructor() {
        console.log('>>> Carousel constructor');
        this.carouselInstances = {};
        this.initCarousel();
    }

    initCarousel() {
        const that = this;
        let carousel = $('.js-carousel');

        $(carousel).each(function(index, element){
            const $container = $(element);
            const settings = {};

            // Set slide settings
            settings.perView        = $container.data('perView') || 1;
            settings.perViewTablet  = $container.data('perViewTablet') || 2;
            settings.perViewMobile  = $container.data('perViewMobile') || 1;
            settings.spaceBetween   = $container.data('spaceBetween') || 0;
            settings.effect         = $container.data('effect') || 'fade';

            // Add class index
            $container.addClass(`carousel-${index}`);
            $container.children('.carousel-bullets').addClass(`bullets-${index}`);
            $container.children('.carousel-pagination').addClass(`pagination-${index}`);

            // If carousel has class -carousel-only-mobile
            if($container.hasClass('-carousel-only-mobile')) {
                if($(window).outerWidth() <= 767) {
                    that.startCarousel(index, settings);
                }
                that.startCarouselOnResize(index, settings);
            } else {
                that.startCarousel(index, settings);
            }
        });
    }

    startCarousel(index, settings) {
        const that = this;
        that.carouselInstances[index] = new Swiper(`.carousel-${index}`, {
            pagination: `.bullets-${index}`,
            nextButton: `.pagination-${index} .carousel-next`,
            prevButton: `.pagination-${index} .carousel-prev`,
            paginationClickable: true,
            slideClass: 'carousel-item',
            wrapperClass: 'carousel-wrapper',
            slidesPerView: settings.perView,
            spaceBetween: settings.spaceBetween,
            effect: settings.effect,
            breakpoints: {
                991: {
                    slidesPerView: settings.perViewTablet,
                },
                767: {
                    slidesPerView: settings.perViewMobile
                }
            }
            // paginationBulletRender: function (swiper, index, className) {
            //     return '<span class="' + className + '">' + (index + 1) + '</span>';
            // }
        });
    }

    startCarouselOnResize(index, settings) {
        const that = this;
        $(window).resize(function() {

            if( $(window).outerWidth() <= 991 && !that.carouselInstances[index] ){
                that.startCarousel(index, settings);

            } else if($(window).outerWidth() >= 992) {

                if ( that.carouselInstances[index] ){
                    that.carouselInstances[index].destroy(false, true);
                    that.carouselInstances[index] = undefined;
                }
            }
        });
    }
}

export default Carousel;
